<template>
  <div class="" style="padding: 25px">
    <!-- <div class="title">开票管理</div> -->
    <!-- <div><a-button style="width:104px;height:40px;" @click="raskAdd" type="primary">新建任务</a-button></div> -->
    <!-- 搜索 -->
    <commonTitle title="开票管理" />
    <div style="margin-bottom: 20px;display:flex;align-items:center;justify-content:space-between">
      <div class="search">
        <span>任务名称：</span>
        <a-input
          style="width: 260px"
          v-model="queryParam.title"
          placeholder="请输入任务名称"
        />
        <div class="table-search-submitButtons">
          <a-button type="primary" @click="handleToSearchEnterprise"
            >查询</a-button
          >
          <a-button
            style="margin-left: 8px"
            icon="reload"
            @click="resetSearchEnterprise"
            >重置</a-button
          >
        </div>
      </div>
      <div style="color:#1890ff;cursor:pointer;width:10%" @click="toInvoiceApply">发票申请记录</div>
    </div>

    <!-- <div style="max-width:1300px"> -->
    <s-table
      ref="raskTables"
      :columns="kkfpColumns"
      :data="kkfpData"
      :rowKey="(record) => record.taskId"
    >
      <span slot="status" slot-scope="text">
        <div class="table-point" v-if="text === '10'"><span></span> 待审核</div>
        <div class="table-point" v-else-if="text === '20'">
          <span></span> 进行中
        </div>
        <div class="table-point" v-else-if="text === '30'">
          <span style="background: #52c41a"></span> 已完成
        </div>
        <div class="table-point" v-else>
          <span style="background: #bfbfbf"></span> 已取消
        </div>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="ticket(record)">申请发票</a>
      </span>
    </s-table>
    <!-- </div> -->
    <!-- 标签页 -->
    <!-- <div class="tags"> -->
    <!-- <a-tabs default-active-key="1" @change="callback"> -->
    <!-- 可开发票 -->
    <!-- <a-tab-pane key="1" tab="可开发票"> -->
    <!-- <div class="content"> -->
    <!-- <a-empty /> -->
    <!-- <div class="bmry"><a-button type="primary" @click="toTicket" :disabled="!hasSelected">去开票</a-button><span v-show="selectedRowKeys.length>0" style="margin-left:15px">已选择{{selectedRowKeys.length}}个</span></div> -->

    <!-- </div> -->
    <!-- </a-tab-pane> -->
    <!-- 已开发票 -->
    <!-- <a-tab-pane key="2" tab="已开发票" force-render>
          <div class="content">
            <s-table
              style="border-top:1px solid #F0F0F0;margin-top:16px"
              ref="raskTable"
              :columns="ykfpColumns"
              :data="ykfpData"
              :rowKey="(record) => record.id"
            >
              <span slot="status" slot-scope="text">
                <div class="table-point" v-if="text==='10'"><span></span> 待审核</div>
                <div class="table-point" v-else-if="text==='20'"><span></span> 进行中</div>
                <div class="table-point" v-else-if="text==='30'"><span style="background:#52C41A"></span> 已完成</div>
                <div class="table-point" v-else><span style="background:#BFBFBF"></span> 已取消</div>
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="ticketDetail(record)">详情</a>
              </span>
            </s-table>
          </div>
        </a-tab-pane> -->
    <!-- </a-tabs> -->
    <!-- </div> -->
  </div>
</template>
<script>
import STable from "@/components/table/";
import commonTitle from "../components/commonTitle";
import {
  ticketList,
  getApplyInvoiceTradeList,
  invoiceList,
  getChanneConfirmInvoiceList,
} from "@/api/api";
import moment from "moment";
export default {
  components: {
    STable,commonTitle,
  },
  data() {
    return {
      queryParam: {
        title: "",
      },
      kkfpColumns: [
        
        {
          title: "企业名称",
          dataIndex: "enterpriseName",
        },
        {
          title: "任务名称",
          dataIndex: "title",
        },
        {
          title: "开票金额合计(元)",
          dataIndex: "invoiceAmount",
          customRender: (text) => (text === null ? "--" : text.toFixed(2)),
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
        },
        
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      ykfpColumns: [
        {
          title: "申请发票时间",
          dataIndex: "invoiceTime",
        },
        {
          title: "任务名称",
          dataIndex: "title",
        },
        {
          title: "开票金额合计(元)",
          dataIndex: "invoiceAmount",
          customRender: (text) => text.toFixed(2),
        },
        // {
        //   title: '付款周期开始时间',
        //   dataIndex: 'payPeriodStartTime',
        // },
        // {
        //   title: '付款周期结束时间',
        //   dataIndex: 'payPeriodEndTime',
        // },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
      pagenation: {
        showSizeChanger: true,
        total: 1000,
        showTotal: (total) => `共有 ${total} 条`,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        pageSize: this.pageSize,
        defaultCurrent: 1,
        onChange: this.onPagerChange,
        onShowSizeChange: this.onPagerSizeChange,
      },
    };
  },
  computed: {
    // 开票是否已选择
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    //查看发票申请记录
    toInvoiceApply(){
      console.log(666);
      window.localStorage.setItem('menu','financeManage');
      this.$router.push('/financeManage')
    },
    onPagerChange(page, pageSize) {
      console.log(page, pageSize);
    },
    // 可开发票
    kkfpData(parameter) {
      let params = {
        status: "30",
        invoiceStatus: "10",
        complateTime: moment().locale("zh-cn").format("YYYY-MM-DD"),
      };
      return getChanneConfirmInvoiceList(
        Object.assign(parameter, this.queryParam)
      ).then((res) => {
        return res.result;
      });
    },
    // 已开发票
    ykfpData(parameter) {
      let params = {
        invoiceStatus: "20",
      };
      return invoiceList(
        Object.assign(parameter, params, this.queryParam)
      ).then((res) => {
        return res.result;
      });
    },
    // 查询
    handleToSearchEnterprise() {
      this.$refs.raskTable.refresh(true);
      this.$refs.raskTables.refresh(true);
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        title: undefined,
      };
      // this.$refs.raskTable.refresh(true)
      this.$refs.raskTables.refresh(true);
    },
    // 选择标签
    callback(key) {
      console.log(key);
    },
    // 可开发票
    onSelectChange(selectedRowKeys, selectedRows) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys,selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 去开票
    ticket(record) {
      window.localStorage.setItem("submenu", "ticketSure");
      window.sessionStorage.setItem("ticketInfo", JSON.stringify(record));
      this.$router.push("/ticketSure");
    },
    // 去开票
    toTicket() {
      window.localStorage.setItem("submenu", "ticketSure");
      this.$router.push("/ticketSure");
    },
    // 历史开票
    // 详情
    ticketDetail(record) {
      window.localStorage.setItem("submenu", "ticketDetail");
      window.sessionStorage.setItem("ticketInfo", JSON.stringify(record));
      this.$router.push("/ticketDetail");
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  font-size: 24px;
  color: #202020;
  margin-bottom: 26px;
}
.search {
  
  display: flex;
  align-items: center;
}
.table-search-submitButtons {
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 30px;
}
.tags {
  margin-top: 20px;
}
.table-point {
  display: flex;
  align-items: center;
  span {
    width: 4px;
    height: 4px;
    background: #1890ff;
    border-radius: 50%;
    margin-right: 4px;
  }
}
</style>
